<template>
  <div class="video-container">
    <div class="video-wrapper">
      <MoboPlayer :source="source" :options="MoboPlayerOptions" class="test"/>
    </div>
    <div>
      <MoboPlayer :source="sourceA" :options="MoboPlayerOptions" class="test"/>
      <MoboPlayer :source="sourceB" :options="MoboPlayerOptions" class="test"/>
      <MoboPlayer :source="sourceC" :options="MoboPlayerOptions" class="test"/>
      <MoboPlayer :source="sourceE" :options="MoboPlayerOptions" class="test"/>
      <MoboPlayer :source="sourceF" :options="MoboPlayerOptions" class="test"/>
      <MoboPlayer :source="sourceG" :options="MoboPlayerOptions" class="test"/>
      <MoboPlayer :source="sourceH" :options="MoboPlayerOptions" class="test"/>
    </div>
  </div>
</template>

<script>
import MoboPlayer from "@/components/MoboPlayer.vue";

export default {
  name: 'App',

  data() {
    return {
      source: 'video/mlove.mp4', // 替换为实际视频文件的路径
      sourceA: 'video/A.mp4', // 替换为实际视频文件的路径
      sourceB: 'video/B.mp4', // 替换为实际视频文件的路径
      sourceC: 'video/C.mp4', // 替换为实际视频文件的路径
      sourceE: 'video/E.mp4', // 替换为实际视频文件的路径
      sourceF: 'video/F.mp4', // 替换为实际视频文件的路径
      sourceG: 'video/G.mp4', // 替换为实际视频文件的路径
      sourceH: 'video/H.mp4', // 替换为实际视频文件的路径

      MoboPlayerOptions: {
        autoplay: false,
        theme: '#FADFA3',
      },
      isMobile: false,
    };
  },

  components: {
    MoboPlayer
  },

  mounted() {
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile();
  },

  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
}
</script>

<style>
body {
  animation: gradientAnimation 10s ease alternate infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.video-container {
  //display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
}

.video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.dplayer {
  max-width: 100%;
  height: auto;
  border-radius: 10px;

  /* 添加条件判断 */

}

.test{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 10px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
