<template>
  <div className="dplayer-container" ref="dplayerContainer"></div>
</template>

<script>
import DPlayer from 'dplayer';

export default {
  name: 'MoboPlayer',
  props: {
    source: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isPlaying: false
    };
  },
  mounted() {
    this.initDPlayer();
  },
  beforeUnmount() {
    if (this.player) {
      this.player.destroy();
    }
  },
  watch: {
    source() {
      // 当切换视频源时，停止当前视频的播放
      this.player.pause();
      this.isPlaying = false;
    }
  },
  methods: {
    initDPlayer() {
      this.player = new DPlayer({
        container: this.$refs.dplayerContainer,
        video: {
          url: this.source
        },
        ...this.options
      });

      this.player.on('play', () => {
        this.isPlaying = true;
      });

      this.player.on('pause', () => {
        this.isPlaying = false;
      });
    }
  }
};
</script>

<style scoped>
.dplayer-container {
  width: 100%;
  height: 100%;
}
</style>
